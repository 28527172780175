import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import socials from "../../consts/socials";
import useScrollBlock from "../../Hooks/useScrollBlock";
import LogoSrc from "./assets/COWCOW_inverted.svg";
import styles from "./Modal.module.scss";

const Modal = ({ content, onDismiss }) => {
  const renderSocialsMobile = () => {
    return socials.map((social) => {
      return (
        <li key={social.route} className={styles.nav_item}>
          <a href={social.route}>
            <img src={social.iconMobile} alt={social.route} />
          </a>
        </li>
      );
    });
  };
  const { root, modal, modal_header, modal_body, modal_footer, logo, toggle } =
    styles;

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onDismiss();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={root}>
      <div className={modal} ref={ref}>
        <div className={modal_header}>
          <div>
            <img src={LogoSrc} alt={"cowcow"} className={logo} />
          </div>

          <span className={toggle} onClick={onDismiss}>
            X
          </span>
        </div>
        <div className={modal_body}>{content}</div>
        <div className={modal_footer}>
          <ul>{renderSocialsMobile()}</ul>
        </div>
      </div>
    </div>
  );
};

export default Modal;
