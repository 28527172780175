export const crew_list = [
  // {
  //   avatar: process.env.PUBLIC_URL + "assets/Team/zed.png",
  //   name: "Zed",
  //   role: "CEO",
  //   description:
  //     "Zed is a true visionary and a wild card; he is a master of turning the unconventional into the cutting-edge.",
  //   twitter: "https://twitter.com/Iamnotzed",
  // },
  // {
  //   avatar: process.env.PUBLIC_URL + "assets/Team/gunner.png",
  //   name: "Gunner",
  //   role: "CTO",
  //   description:
  //     "From traditional banking to decentralized finance, a software developer now shaping the future of Web3.",
  // },
  // {
  //   avatar: process.env.PUBLIC_URL + "assets/Team/doppy.png",
  //   name: "Doppy",
  //   role: "COO",
  //   description:
  //     "Business developer for Cow Cow, with a sharp mind and a knack for negotiation, Doppy is the ultimate deal-maker.",
  //   twitter: "https://twitter.com/DoppyCow",
  // },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/ara-ara.png",
    name: "Ara Ara",
    role: "CMO",
    description:
      "Meet Ara Ara, our CMO. This master of marketing is a true crowd-pleaser and can turn any NFT into a hot commodity.",
    twitter: "https://twitter.com/cowcowaraara",
  },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/archy.png",
    name: "Archy",
    role: "HEAD OF GROWTH",
    description:
      "The degen-business guy, Archy is our box to box magician, the glue that holds our empire together. Getting sh*t done.",
  },
  // {
  //   avatar: process.env.PUBLIC_URL + "assets/Team/axel.png",
  //   name: "Axel",
  //   role: "HEAD OF COMMUNITY",
  //   description:
  //     "As the head of community for Cow Cow, Axel is the ultimate herd leader. His talent is making any community moo-ve.",
  // },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/rick.png",
    name: "Rick",
    role: "Business Developer",
    description:
      "A creative but also strongly analytical MultiversX jack of all trades, bringing moo-re ecosystem partners to the herd.",
  },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/gng.png",
    name: "GNG",
    role: "SOCIAL MEDIA MANAGER",
    description:
      "Lead the herd & moo-ve the internet, create viral content for a moo-valous time as a social media manager for Cow Cow.",
  },
  // {
  //   avatar: process.env.PUBLIC_URL + "assets/Team/manny.png",
  //   name: "Manny",
  //   role: "ART DIRECTOR",
  //   description:
  //     "Manny is the ultimate digital da Vinci. He can often be found creating impromptu masterpieces on the office whiteboard.",
  // },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/jeff.png",
    name: "JEFF",
    role: "CREATIVE DIRECTOR",
    description:
      "With Jeff, you can be sure that Cow Cow will be visually stunning and always pushing the boundaries of creativity.",
  },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/dsr.png",
    name: "DEESER",
    role: "ARTIST",
    description:
      "Deeser has a talent for turning pixels into masterpieces and can make even the most mundane cow into a work of art.",
  },
  {
    avatar: process.env.PUBLIC_URL + "assets/Team/andrew.png",
    name: "Andrew",
    role: "Illustrator",
    description:
      "A true visionary can turn a blank canvas into a masterpiece with just a few brush strokes.",
  },
];
