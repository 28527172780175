const socials = [
  {
    icon: `${process.env.PUBLIC_URL}assets/UI/discord.svg`,
    iconMobile: `${process.env.PUBLIC_URL}assets/UI/discord-brown.svg`,
    route: "https://discord.gg/two3labs",
  },
  {
    icon: `${process.env.PUBLIC_URL}assets/UI/twitter.svg`,
    iconMobile: `${process.env.PUBLIC_URL}assets/UI/twitter-brown.svg`,
    route: "https://twitter.com/wecowcow",
  },
];
export default socials;
