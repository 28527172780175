const links = [
  { title: "Home", route: "/" },
  { title: "The City", route: "/thecity" },
  { title: "Urban Plan", route: "/urban-plan" },
  { title: "Staking", route: "https://staking.cowcow.io" },

  {
    title: "More",
    route: "",
    submenu: [
      { title: "#wecowcow", route: "/thebrand" },
      { title: "Manifesto", route: "/manifesto" },
      { title: "Team", route: "/team" },
      { title: "Dressing", route: "https://dressing.cowcow.io" },
    ],
  },
];

export default links;
