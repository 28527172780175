export const PageTransitionAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: "easeOut", duration: 0.25 },
  exit: { opacity: 0 },
};

export const HomeGridCellAnimation = {
  initial: { opacity: 0, y: "50%", scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
  transition: { ease: "easeOut", duration: 0.75 },
};

export const FadeInWhileInView = {
  initial: { opacity: 0 },
  transition: { ease: "easeOut", duration: 0.75, delay: 0.5 },
  whileInView: { opacity: 1 },
  viewport: { once: true },
};

export const BounceUpWhileInView = {
  initial: { y: 200, opacity: 0 },
  transition: { type: "spring", bounce: 0.1, duration: 0.75, ease: "easeOut" },
  whileInView: { y: 0, opacity: 1 },
  viewport: { once: true, amount: 0.5 },
};

export const DiagonalLeftWhileInView = {
  initial: { x: -125, y: 125, opacity: 0 },
  transition: { type: "spring", bounce: 0.1, duration: 0.75, ease: "easeOut" },
  whileInView: { x: 0, y: 0, opacity: 1 },
  viewport: { once: true, amount: 0.25 },
};

export const DiagonalRightWhileInView = {
  initial: { x: 125, y: 125, opacity: 0 },
  transition: { type: "spring", bounce: 0.1, duration: 0.75, ease: "easeOut" },
  whileInView: { x: 0, y: 0, opacity: 1 },
  viewport: { once: true, amount: 0.25 },
};

export const FadeOutAnimation = {
  active: { opacity: 1 },
  inactive: { opacity: 0 },
};
