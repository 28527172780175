import React, { useEffect, useState } from "react";
import styles from "./AnticipationPage.module.scss";
import Header from "../../Components/Header/Header";
import Loader from "../../Components/Loader/Loader";
import { useWindowSize } from "react-use";
import ReactConfetti from "react-confetti";

import { ExtensionProvider } from "@elrondnetwork/erdjs-extension-provider";
import { useSearchParams } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";

const AnticipationPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const [response, setResponse] = useState(null);
  const [accessCode, setAccessCode] = useState("");
  const [canView, setCanView] = useState(false);

  //get elrond key from param
  const [searchParams, setSearchParams] = useSearchParams();

  const { width, height } = useWindowSize();

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const provider = ExtensionProvider.getInstance();
  var formData = new FormData();

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    if (totalImages === imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        document.body.style.overflowY = "scroll";
      }, 1000);
    }
  }, [imagesLoaded]);
  const totalImages = 0; // modify this if the number of images on page will change

  //set wallet connected maiar
  useEffect(() => {
    if (searchParams.get("address")) {
      sessionStorage.setItem(
        "elrondPublicAddress",
        searchParams.get("address")
      );
      setIsWalletConnected(true);
    }
  }, []);

  const getNftStatus = (formData) => {
    setLoaderVisible(true);
    fetch("https://twitterapi.cowcow.io", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        setResponse(json.length);
        setLoaderVisible(false);
      });
  };

  const checkWallet = () => {
    formData.append("action", "check_wallet");
    formData.append("wallet", sessionStorage.getItem("elrondPublicAddress"));
    getNftStatus(formData);
  };

  //set wallet connected defi
  const handleDefiLogin = async () => {
    await provider.init();
    if (!provider.isInitialized()) {
      window.open(
        "https://chrome.google.com/webstore/detail/multiversx-defi-wallet/dngmlblcodfobpdpecaadgfbcggfjfnm",
        "_ blank"
      );
    } else {
      const defiAddress = await provider.login();
      sessionStorage.setItem("elrondPublicAddress", defiAddress);
      setIsWalletConnected(true);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("elrondPublicAddress")) {
      setCanView(true);
      setIsWalletConnected(true);
    }
  }, []);

  const handleCheckAccess = (e) => {
    e.preventDefault();
    if (accessCode.toLowerCase() === "elementx") {
      setCanView(true);
    }
  };
  const handleInputChange = (e) => {
    setAccessCode(e.target.value);
  };

  // useEffect(() => {
  //   document
  //     .querySelector("html")
  //     ?.setAttribute("style", "background: var(--brown);");
  //   document.body.style = "background: var(--brown)";

  //   return () => {
  //     document
  //       .querySelector("html")
  //       ?.setAttribute("style", "background: var(--beige);");
  //     document.body.style = "";
  //   };
  // }, []);

  return (
    <div className={styles.page}>
      {isloaderVisible && <Loader />}

      {width < Infinity && height < Infinity && response > 0 && (
        <ReactConfetti width={width - 50} height={height} />
      )}
      <Header />
      <BrowserView>
        <div className={styles.main_content}>
          {!canView && (
            <div>
              <div className={styles.prompt}>Enter access code</div>
              <form onSubmit={(e) => handleCheckAccess(e)}>
                <input
                  type="text"
                  name="code"
                  id="code "
                  value={accessCode}
                  onChange={handleInputChange}
                />
                <input type="submit" value="Submit" />
              </form>
            </div>
          )}
          {response > 0 && (
            <div className={styles.response}>
              <h2>COW COW APPROVED</h2>
              <p>
                You can't connect the dots by only looking forward - you can
                milk the cow only if you know the origins.
              </p>
            </div>
          )}
          {response === 0 && (
            <div className={styles.response}>
              <h2>NO COW COW, NO HOW HOW</h2>
              <p>BUY ONE HERE AND COME BACK</p>
              <a
                href="https://xoxno.com/collection/COW-cd463d"
                target="_blank"
                rel="noopener noreferrer"
              >
                BUY NOW
              </a>
            </div>
          )}

          {canView && isWalletConnected && response === null && (
            <div className={styles.container_buttons}>
              <button onClick={checkWallet}>Check Wallet</button>
            </div>
          )}
          {canView && !isWalletConnected && (
            <div className={styles.container_buttons}>
              <button onClick={handleDefiLogin}>MultiversX DeFi Wallet</button>

              <a
                href={`https://wallet.multiversx.com/hook/login?callbackUrl=${window.location.origin}/back-to-origins`}
              >
                <span>XPortal Wallet</span>
              </a>
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div className={styles.mobile_disclaimer}>
          <h1>You'll get the best experience on a laptop</h1>
          <p>
            For the moment, we don't support mobile devices. Please start the
            application on a device with a larger screen.
          </p>
        </div>
      </MobileView>
      <footer className={styles.footer}>
        <span>&#169; 2023, Cow Cow</span>
        <span>Cow together, vibe together.</span>
      </footer>
    </div>
  );
};

export default AnticipationPage;
