import React from "react";
import styles from "./NFTCard.module.scss";

const NFTCard = ({ image, handleImageLoad }) => {
  return (
    <div className={styles.card}>
      <div className={styles.image}>
        <img src={image} alt={`CowCow-NFT`} onLoad={handleImageLoad} />
      </div>
    </div>
  );
};
export default NFTCard;
