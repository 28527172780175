import React from "react";
import styles from "./ErrorPage.module.scss";
import Page from "../../HOC/Page/Page";

const ErrorPage = () => {
  //const error = useRouteError();
  //console.error(error);
  //{error.statusText || error.message}
  return (
    <main id={"ErrorPage"} className={styles.center_page}>
      <Page>
        <div>
          <h1 className={styles.error_heading}>Nothing to see here</h1>
          <h2 className={styles.error_info}>404 Error</h2>
        </div>
        {/*<p><Link to={'/'}>Back to Homepage</Link></p>*/}
      </Page>
    </main>
  );
};
export default ErrorPage;
