import React, { useRef } from "react";

const CanvasMobile = (props) => {
  const canvasRef = useRef(null);
  return (
    <canvas
      ref={canvasRef}
      {...props}
      id="myCanvasMobile"
      width="960"
      height="2076"
    />
  );
};

export default CanvasMobile;
