import React from "react";
import styles from "./CrewCard.module.scss";

const CrewCard = ({ avatar, name, role, description, twitter }) => {
  return (
    <div className={styles.card}>
      <div className={styles.avatar}>
        <img src={avatar} alt={`CowCow-Crew-${name}`} />
      </div>
      <div className={styles.positioning_container}>
        <h2 className={styles.name}>{name}</h2>
        <h3 className={styles.role}>{role}</h3>
        <p className={styles.description}>{description}</p>
        {twitter && (
          <a href={twitter} target={"_blank"} className={styles.socials}>
            <img
              src={
                process.env.PUBLIC_URL + "assets/Graphics/Socials/Twitter.svg"
              }
              alt={`twitter-${name}`}
            />
          </a>
        )}
      </div>
    </div>
  );
};
export default CrewCard;
