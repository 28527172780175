export const bg_colors = {
  Glow: "#B8CFDA",
  Cream: "#FEEFC4",
  Orange: "#FBDCC9",
  Green: "#CEF7E5",
  Pink: "#FFDAD7",
  Purple: "#EEDDF0",
  White: "#FEF8F4",
  Yellow: "#FFEBCD",
  Blue: "#CFE8F4",
};
