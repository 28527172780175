import React from 'react';
import styles from './PlaceholderPage.module.scss';
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {PageTransitionAnimation} from "../../Animations";

const PlaceholderPage = () => {
    return (
        <motion.main className={styles.page} {...PageTransitionAnimation}>
            <Link to={"/"}>Back</Link>
            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.</h1>
        </motion.main>
    )
}

export default PlaceholderPage;