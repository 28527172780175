import { AnimatePresence } from "framer-motion";
import React, { useEffect, useReducer, useRef, useState } from "react";
import styles from "./ModalUrbanPlan.module.scss";

import { motion } from "framer-motion";

const ModalUrbanPlan = ({ content, onDismiss }) => {
  const {
    root,
    modal,
    modal_header,
    modal_body,
    modal_footer,
    title,
    toggle,
    grid,
  } = styles;

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onDismiss();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onDismissModal = () => {
    onDismiss();
    setTimeout(() => {
      ref?.current?.scroll({
        top: 0,
      });
    }, 750);
  };

  return (
    <AnimatePresence>
      <div className={root}>
        <div className={modal} ref={ref}>
          <div className={modal_header}>
            <motion.div className={title}>{content.title}</motion.div>

            <motion.span className={toggle} onClick={onDismissModal}>
              <span>Back &nbsp;</span>
              <span className={toggle}>X</span>
            </motion.span>
          </div>
          <motion.div className={modal_body}>
            <div className={grid}>
              <div>
                {content.content &&
                  content.content.map((element) => (
                    <div key={element.image}>
                      <h2>{element.title}</h2>
                      <p>{element.description}</p>
                    </div>
                  ))}
              </div>
              <div>
                <img src={content.image} alt={content.title} />
              </div>
            </div>
          </motion.div>
          <div className={modal_footer}></div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default ModalUrbanPlan;
