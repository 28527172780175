import React, { useEffect, useState } from "react";
import styles from "./NonSensePage.module.scss";
import VideoSource from "./assets/COWCOW.mp4";
import Header from "../../Components/Header/Header";
import Loader from "../../Components/Loader/Loader";

const NonSensePage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    if (totalImages === imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        document.body.style.overflowY = "scroll";
      }, 1000);
    }
  }, [imagesLoaded]);
  const totalImages = 1; // modify this if the number of images on page will change

  return (
    <div className={styles.page}>
      {isloaderVisible && <Loader />}

      <Header />
      <div className={styles.video_container}>
        <video
          autoPlay
          muted
          loop
          playsInline
          onLoadedData={() =>
            setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
          }
        >
          <source src={VideoSource} type="video/mp4" />
        </video>
      </div>

      <footer className={styles.footer}>
        <div className={styles.buttons}>
          <a
            href="https://cowcow.io/ip-license.pdf"
            target="_blank"
            className={styles.flex_button}
          >
            IP License
          </a>
          <a href="https://cowcow.io/terms-and-conditions.pdf" target="_blank">
            T&C
          </a>
        </div>
        <span>&#169; 2023, COWCOW &#169;</span>
        <div className={styles.legal}>
          "COWCOW®" is a registered trademark of Two3 Labs. Officially
          recognized under the European Union Intellectual Property Office
          (EUIPO) registration number 018833170, dated 14/12/2023. All rights
          reserved. The use of the COWCOW trademark, including the name and
          associated digital assets, is governed by specific terms and cannot be
          transferred or used without explicit permission from Two3 Labs. For
          inquiries or more information, please contact legal@cowcow.io.
        </div>
      </footer>
    </div>
  );
};

export default NonSensePage;
