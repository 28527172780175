import React from "react";
import styles from "./SelectHorizontal.module.scss";
import { AnimatePresence, motion } from "framer-motion";

const steps = ["Twitter Banner", "Mobile Wallpaper", "High Quality PFP"];

const SelectHorizontal = ({ step, setStep }) => {
  const handleNext = () => {
    if (step === 2) {
      setStep(0);
    } else {
      setStep((step) => step + 1);
    }
  };

  const handleBack = () => {
    if (step === 0) {
      setStep(2);
    } else {
      setStep((step) => step - 1);
    }
  };
  return (
    <div className={styles.container}>
      <button className={styles.back} onClick={handleBack}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className={styles.arrow}
        >
          <path
            fillRule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <AnimatePresence>
        <motion.div
          key={step}
          initial={{ opacity: 0, x: 200 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -200 }}
          transition={{ duration: 0.3 }}
          className={styles.step}
        >
          {steps[step]}
        </motion.div>
      </AnimatePresence>
      <button className={styles.next} onClick={handleNext}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className={styles.arrow}
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
export default SelectHorizontal;
