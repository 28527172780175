import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./HomePage.module.scss";
import { motion } from "framer-motion";
import Loader from "../../Components/Loader/Loader";
import { PageTransitionAnimation } from "../../Animations";

import CityThumbnail from "./assets/new/desktop/thecity.png";
import TeamThumbnail from "./assets/new/desktop/team.png";
import ManifestoThumbnail from "./assets/new/desktop/manifesto.png";
import OriginsThumbnail from "./assets/new/desktop/origins.png";
import BellThumbnail from "./assets/new/desktop/bell.png";
import KeyThumbnail from "./assets/new/desktop/key.png";
import ScowlarshipThumbnail from "./assets/new/desktop/scowlarship.png";
import UrbanPlanThumbnail from "./assets/new/desktop/urban-plan.png";
import TwentyThreeThumbnail from "./assets/new/desktop/23.png";
import ConeThumbnail from "./assets/new/desktop/cone.png";
import GalleryThumbnail from "./assets/new/desktop/gallery.png";
import CowCowThumbnail from "./assets/new/desktop/cowcow.png";

import CityThumbnailHover from "./assets/new/hover/thecity.png";
import TeamThumbnailHover from "./assets/new/hover/team.png";
import ManifestoThumbnailHover from "./assets/new/hover/manifesto.png";
import OriginsThumbnailHover from "./assets/new/hover/origins.png";
import BellThumbnailHover from "./assets/new/hover/bell.png";
import KeyThumbnailHover from "./assets/new/hover/key.png";
import ScowlarshipThumbnailHover from "./assets/new/hover/scowlarship.png";
import UrbanPlanThumbnailHover from "./assets/new/hover/urban-plan.png";
import TwentyThreeThumbnailHover from "./assets/new/hover/23.png";
import ConeThumbnailHover from "./assets/new/hover/cone.png";
import GalleryThumbnailHover from "./assets/new/hover/gallery.png";
import CowCowThumbnailHover from "./assets/new/hover/cowcow.png";

import MobileCityThumbnail from "./assets/new/mobile/thecity.png";
import MobileTeamThumbnail from "./assets/new/mobile/team.png";
import MobileManifestoThumbnail from "./assets/new/mobile/manifesto.png";
import MobileOriginsThumbnail from "./assets/new/mobile/origins.png";
import MobileBellThumbnail from "./assets/new/mobile/bell.png";
import MobileKeyThumbnail from "./assets/new/mobile/key.png";
import MobileScowlarshipThumbnail from "./assets/new/mobile/scowlarship.png";
import MobileUrbanPlanThumbnail from "./assets/new/mobile/urban-plan.png";
import MobileTwentyThreeThumbnail from "./assets/new/mobile/23.png";
import MobileConeThumbnail from "./assets/new/mobile/cone.png";
import MobileGalleryThumbnail from "./assets/new/mobile/gallery.png";
import MobileCowCowThumbnail from "./assets/new/mobile/cowcow.png";
import Modal from "../../Components/Modal/Modal";
import {
  tba_23,
  tba_bell,
  tba_cone,
  tba_gallery,
  tba_key,
  tba_origins,
} from "../../consts/tba_texts";

const HomePage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const totalImages = 12; // modify this if the number of images on page will change
  useEffect(() => {
    if (totalImages === imagesLoaded) {
      setTimeout(() => setLoaderVisible(false), 1000);
    }
  }, [imagesLoaded]);

  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalDismiss = () => {
    setIsModalOpen(false);
  };
  return (
    <motion.main
      key={"home-page"}
      className={styles.home_page}
      {...PageTransitionAnimation}
    >
      <div className={styles.widget_side}>
        <a
          href="https://twitter.com/wecowcow"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/twitter.svg`}
            alt="Twitter"
          />
        </a>
        <a
          href="https://www.instagram.com/wecowcow/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/instagram.svg`}
            alt="Instagram"
          />
        </a>
        <a
          href="https://discord.gg/two3labs"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/discord.svg`}
            alt="Discord"
          />
        </a>
        <a
          href="https://medium.com/@wecowcow"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/dotdot.svg`}
            alt="Medium"
          />
        </a>

        <a
          href="https://xoxno.com/collection/COW-cd463d"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/xoxno.svg`}
            alt="XOXNO"
          />
        </a>

        <a
          href="https://www.frameit.gg/marketplace/COW-cd463d/items"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.social_icon}
        >
          <img
            src={`${process.env.PUBLIC_URL}assets/UI/frameit.svg`}
            alt="FrameIt"
          />
        </a>
      </div>
      {isModalOpen && (
        <Modal content={modalContent} onDismiss={handleModalDismiss} />
      )}

      <article className={styles.home_grid}>
        {isloaderVisible && <Loader />}

        <div className={`${styles.elem_1}`}>
          <Link to={"/thecity"} className={styles.cell1}>
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              <img
                src={CityThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) => (e.currentTarget.src = CityThumbnailHover)}
                onMouseLeave={(e) => (e.currentTarget.src = CityThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_2}`}>
          <Link
            to={"#"}
            className={styles.cell1}
            onClick={() => {
              setModalContent(tba_23);
              setIsModalOpen(true);
            }}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={TwentyThreeThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={TwentyThreeThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = TwentyThreeThumbnailHover)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.src = TwentyThreeThumbnail)
                }
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_3}`}>
          <Link
            to={"#"}
            className={styles.cell1}
            onClick={() => {
              setModalContent(tba_origins);
              setIsModalOpen(true);
            }}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={OriginsThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={OriginsThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = OriginsThumbnailHover)
                }
                onMouseLeave={(e) => (e.currentTarget.src = OriginsThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_4}`}>
          <Link to={"/team"} className={styles.cell1}>
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={TeamThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={TeamThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) => (e.currentTarget.src = TeamThumbnailHover)}
                onMouseLeave={(e) => (e.currentTarget.src = TeamThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_5}`}>
          <Link
            to="/urban-plan"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={UrbanPlanThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={UrbanPlanThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = UrbanPlanThumbnailHover)
                }
                onMouseLeave={(e) => (e.currentTarget.src = UrbanPlanThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_6}`}>
          <a
            href={"https://dressing.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={GalleryThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={GalleryThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = GalleryThumbnailHover)
                }
                onMouseLeave={(e) => (e.currentTarget.src = GalleryThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </a>
        </div>
        <div className={`${styles.elem_7}`}>
          <Link to={"/thebrand"} className={styles.cell1}>
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={CowCowThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={CowCowThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = CowCowThumbnailHover)
                }
                onMouseLeave={(e) => (e.currentTarget.src = CowCowThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_8}`}>
          <a
            href={"https://scowlarship.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={ScowlarshipThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={ScowlarshipThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = ScowlarshipThumbnailHover)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.src = ScowlarshipThumbnail)
                }
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </a>
        </div>
        <div className={`${styles.elem_9}`}>
          <a
            href={"https://staking.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={ConeThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={ConeThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) => (e.currentTarget.src = ConeThumbnailHover)}
                onMouseLeave={(e) => (e.currentTarget.src = ConeThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </a>
        </div>
        <div className={`${styles.elem_10}`}>
          <Link to={"/manifesto"} className={styles.cell1}>
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={ManifestoThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={ManifestoThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) =>
                  (e.currentTarget.src = ManifestoThumbnailHover)
                }
                onMouseLeave={(e) => (e.currentTarget.src = ManifestoThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </Link>
        </div>
        <div className={`${styles.elem_11}`}>
          <a
            href={"https://mooveprotocol.ai"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={KeyThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={KeyThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) => (e.currentTarget.src = KeyThumbnailHover)}
                onMouseLeave={(e) => (e.currentTarget.src = KeyThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </a>
        </div>
        <div className={`${styles.elem_12}`}>
          <a
            href={"https://raffles.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.picture
              id={"Cell1"}
              className={`${styles.home_grid__item}`}
            >
              {/* <source
                srcSet={BellThumbnailPadded}
                media="(min-aspect-ratio: 16/9)"
              /> */}
              <img
                src={BellThumbnail}
                alt={"CowCow-Town"}
                className={`${styles.home_grid__item}`}
                onMouseOver={(e) => (e.currentTarget.src = BellThumbnailHover)}
                onMouseLeave={(e) => (e.currentTarget.src = BellThumbnail)}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </motion.picture>
          </a>
        </div>
      </article>
      <article className={styles.home_grid_mobile}>
        <div className={`${styles.elem_1}`}>
          <Link to={"/thecity"} className={styles.cell1}>
            <motion.img
              id={"Cell1"}
              src={MobileCityThumbnail}
              alt={"CowCow-Town"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_2}`}>
          <Link
            to={"#"}
            className={styles.cell1}
            onClick={() => {
              setModalContent(tba_23);
              setIsModalOpen(true);
            }}
          >
            <motion.img
              id={"Cell1"}
              src={MobileTwentyThreeThumbnail}
              alt={"CowCow-Town"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_3}`}>
          <Link
            to={"#"}
            className={styles.cell1}
            onClick={() => {
              setModalContent(tba_origins);
              setIsModalOpen(true);
            }}
          >
            <motion.img
              id={"Cell1"}
              src={MobileOriginsThumbnail}
              alt={"CowCow-Town"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_4}`}>
          <Link to={"/team"} className={styles.cell1}>
            <motion.img
              id={"Cell1"}
              src={MobileTeamThumbnail}
              alt={"CowCow-Town"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_5}`}>
          <Link
            to="/urban-plan"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileUrbanPlanThumbnail}
              alt={"CowCow Urban Plan"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_6}`}>
          <a
            href={"https://dressing.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileGalleryThumbnail}
              alt={"CowCow Gallery"}
              className={`${styles.home_grid__item}`}
            />
          </a>
        </div>
        <div className={`${styles.elem_7}`}>
          <Link to={"/thebrand"} className={styles.cell1}>
            <motion.img
              id={"Cell1"}
              src={MobileCowCowThumbnail}
              alt={"CowCow"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_8}`}>
          <a
            href={"https://scowlarship.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileScowlarshipThumbnail}
              alt={"CowCow Scowlarships"}
              className={`${styles.home_grid__item}`}
            />
          </a>
        </div>
        <div className={`${styles.elem_9}`}>
          <a
            href={"https://staking.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileConeThumbnail}
              alt={"CowCow Cone"}
              className={`${styles.home_grid__item}`}
            />
          </a>
        </div>
        <div className={`${styles.elem_10}`}>
          <Link to={"/manifesto"} className={styles.cell1}>
            <motion.img
              id={"Cell1"}
              src={MobileManifestoThumbnail}
              alt={"CowCow-Town"}
              className={`${styles.home_grid__item}`}
            />
          </Link>
        </div>
        <div className={`${styles.elem_11}`}>
          <a
            href={"https://mooveprotocol.ai"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileKeyThumbnail}
              alt={"CowCow Key"}
              className={`${styles.home_grid__item}`}
            />
          </a>
        </div>
        <div className={`${styles.elem_12}`}>
          <a
            href={"https://raffles.cowcow.io"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell1}
          >
            <motion.img
              id={"Cell1"}
              src={MobileBellThumbnail}
              alt={"CowCow Bell"}
              className={`${styles.home_grid__item}`}
            />
          </a>
        </div>
      </article>
    </motion.main>
  );
};

export default HomePage;
