export const atelier_obj = {
  title: "Atelier",
  content: [
    {
      title: "",
      description:
        "Cow Cow is a web3 prime brand. In order to gain exposure, it is essential to take Cow Cow beyond the digital realm and bring it into the real world.",
    },
    {
      title: "Cross-chain partnerships:",
      description:
        "We will continue to grow Cow Cow by doing multiple cross-chain collaborations, combining stories, shared values, and communities. We're going to travel from the mountains to the sea and meet new friends along the way.",
    },
    {
      title: "Real-world marketing:",
      description:
        "We're going to make Cow Cow memorable and easily recognizable through IRL mass marketing. For example, Cow Cow will appear in a music video, or on TV. One thing is clear: we know all the hype tactics to attract new audiences and make Cow Cow easily distinguishable by anybody.",
    },
    {
      title: "IP Rights:",
      description:
        "Intellectual property rights are offering our NFT holders a new way to prove ownership and control their digital assets. It will serve as a powerful tool for protecting and monetizing digital content.",
    },
    {
      title: "",
      description:
        "By holding a Cow Cow NFT, our community will get access to an ever-expanding set of IP rights.We are going to create a brand kit that includes the Cow Cow wordmarks, community seal, mascot, typefaces and colors.",
    },
    {
      title: "",
      description:
        "This brand kit is exclusive to Cow Cow holders allowing them to build incredible personal and commercial brands while abiding by our standards.We want to empower and inspire you to take your Cow Cow from an NFT to a stand-alone brand.",
    },
  ],
  image: "assets/UrbanPlan/atelier.webp",
};

export const town_square_obj = {
  title: "Town Square",
  content: [
    {
      title: "",
      description:
        "Real-life events, meet-ups, and exhibitions will give our community an immersive and unforgettable experience. These will provide us a chance to connect with our members and expand our reach beyond the digital world.",
    },
    {
      title: "IRL Events:",
      description:
        "We will run frequent IRL meetups for our community members. These events will come in various forms that offer a wide range of experiences such as pop-up shops, parties, and music festivals, you name it. Get ready to Cow Cow!",
    },
    {
      title: "Conferences:",
      description:
        "We’re exploring the opportunities of organizing large cross-chain NFT Conferences to show our culture to the world. Cow Cow holders will automatically be the VIPs there, having free access and other perks.",
    },
    {
      title: "Exhibitions:",
      description:
        "We will break down the barriers that currently exist between real life and web3 space by showcasing our digital art and collectibles in physical galleries. This will allow our art to reach a wider audience and also provide a new way for people to experience and engage with web3 art. Also, our holders will be able to take advantage of the IP rights by showcasing their NFT and collectibles.",
    },
  ],
  image: "assets/UrbanPlan/town-square.webp",
};

export const mall_obj = {
  title: "The Mall",
  content: [
    {
      title: "",
      description:
        "Forget the usual merch you are used to ordering, everybody can print a picture on a shirt. Cow Cow brand will become a way of life through our merch that will be used by community members in daily activities.",
    },
    {
      title: "Streetwear and collectibles:",
      description:
        "From fashionable wearables like t-shirts, hoodies, and hats, to everyday essentials like mugs, phone cases, and mouse pads. Providing merchandise and collectibles not only increases brand exposure but also fosters a sense of community among our holders, bringing them closer to the brand.",
    },
    {
      title: "Fashion brand partnerships:",
      description:
        "We’re teaming up with well-known fashion brands to bring the web3 world closer to the real life and create limited-edition custom assets like 1/1 Cow Cow NFTs paired with real-life wearables.",
    },
  ],
  image: "assets/UrbanPlan/mall.webp",
};

export const uni_obj = {
  title: "University",
  content: [
    {
      title: "",
      description:
        "Cow Cow NFT Incubator will be a program that helps, supports, and advise the development and growth of NFT projects. A nurturing environment that will provide resources, mentorship, and a team of industry experts. Of course, all the holders of Cow Cow NFTs will be provided with whitelists, free mints, and other benefits in the incubated projects.",
    },
    {
      title: "Workshops and training programs:",
      description:
        "The Cow Cow Incubator will offer a wide range of educational resources, such as workshops and training programs, to help NFT creators learn more about the technology and best practices for creating and managing NFT projects. We provide hands-on learning opportunities, expert guidance, and access to the latest tools and trends to stay ahead of the curve.",
    },
    {
      title: "Funding and resources:",
      description:
        "Funding and networking are both crucial elements for the success of any NFT project looking to take off and grow. We can offer grants and investments to cover the costs of development, marketing, scaling, and other expenses associated with launching an NFT project. We also provide numerous opportunities for creators to connect with potential collaborators, partners, and investors through our network.",
    },
    {
      title: "Access to marketplaces and platforms:",
      description:
        "We place a strong emphasis on providing access to marketplaces and launchpads through our established relationships with some of the most reputable and well-respected platforms in the NFT space. This will enable exposure to a highly engaged and wider audience.",
    },
  ],
  image: "assets/UrbanPlan/uni.webp",
};

export const factory_obj = {
  title: "Factory",
  content: [
    {
      title: "",
      description:
        "By building various types of decentralized apps and software, we are looking to produce new recurring revenue streams associated with the project.",
    },
    {
      title: "",
      description:
        "All the benefits resulting from product building will be redirected to our holders in various forms.",
    },
    {
      title: "Scowlarship:",
      description:
        "The first product developed by Cow Cow, the Scowlarship represents a new way to grow your community and offer different perks to the members. Used mostly as the main whitelisting tool, the Scowlarship is customizable and works as a standalone, white-label web application that can be used by other projects.",
    },
    {
      title: "Web Gallery:",
      description:
        "Cow Gallery is the place where cows come to life, offering different experiences at every turn. Here you will be able to personalize your Cow Cow in different ways. Whether it is a classy occasion or a themed one, you can make your Cow Cow represent that event. Holders can unleash their creativity with a wide range of assets and generate a new Twitter banner or mobile wallpaper, increasing social media visibility.",
    },
    {
      title: "E-commerce Web3 Merch Shop:",
      description:
        "Holder only e-commerce platform with exclusive merch available built with web3 integration.",
    },
  ],
  image: "assets/UrbanPlan/factory.webp",
};

export const social_hub_obj = {
  title: "Social Hub",
  content: [
    {
      title: "",
      description:
        "We are guided by a simple but complex idea: the power of community. We are breaking the barriers between chains, bringing together people with a shared belief in the community.",
    },
    {
      title: "DAO DAO: Cow Cow Cowmoonity",
      description:
        "We are going to experiment governance structures in order to solidify the base of the project. A self-sustaining, self-governing, on-chain DAO.",
    },
    {
      title: "Alpha hunting:",
      description:
        "Deep diving into markets, full project analysis. Daily NFT trading tips from the best known cross-chain experts.",
    },
    {
      title: "Educational resources:",
      description:
        "Cow Cow holders will get access to valuable tools, resources, and opportunities in order to get the right guidance in the NFT world.",
    },
    {
      title: "Early access opportunities:",
      description:
        "We intend to offer our community a multi-chain collab team that will grant members exclusive access to all the most anticipated upcoming projects. Testimony to this stand the 1000+ collaborations that we completed in the first few weeks already",
    },
    {
      title: "Vibes, raids, memes, culture, fun, growth, activities:",
      description:
        "Cow Cow hangouts daily. Each and every day. We will watch moovies, play games and argue about sports. We will plan about how to moove the mountains. Let's laugh together, grow together and all that we dream of, we're gonna build together.",
    },
  ],
  image: "assets/UrbanPlan/social-hub.webp",
};

export const central_station_obj = {
  title: "Central Station",
  content: [
    {
      title: "",
      description:
        "Well, here everything is going to be a surprise. Expect diving in the past, adventures in the present, and surprises in the future. As deliverables, be sure you will see visual materials of all types, gamification, and who knows, maybe other exclusive NFT drops.",
    },
    {
      title: "",
      description:
        "PS. The train to the metaverse leaves from platform 3 D. Don’t miss your opportunity to venture around the digital worlds with Cow Cow.",
    },
  ],
  image: "assets/UrbanPlan/central-station.webp",
};
