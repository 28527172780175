import React, { useEffect, useRef, useState } from "react";
import styles from "./ModalGallery.module.scss";

import { motion } from "framer-motion";
import { bg_colors } from "../../consts/bg_colors";
import CanvasTwitter from "../Canvas/CanvasTwitter";

import LogoSrc from "./assets/logo_new.svg";
import FrameIt from "./assets/frameit.svg";
import XOXNO from "./assets/xoxno.svg";
import SelectHorizontal from "../SelectHorizontal/SelectHorizontal";
import CanvasMobile from "../Canvas/CanvasMobile";
import { rarities } from "../../consts/rarity";

const ModalGallery = ({ content, onDismiss }) => {
  const { root, modal, modal_header, modal_body, title, toggle } = styles;

  const { hqImage, hqImageModal, background, name, id, traits } = content;

  //canvas state

  const [elementsLoaded, setElementsLoaded] = useState(0);
  const [type, setType] = useState(0); // 0 for Twitter, 1 for Mobile BG, 2 for PFP
  const [imageLoaded, setImageLoaded] = useState(false); // 0 for Twitter, 1 for Mobile BG, 2 for PFP

  /* canvas functions */
  const drawOnTwitterCanvas = () => {
    const canvas = document.getElementById("myCanvas");
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = bg_colors[background];

    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // ctx.imageSmoothingEnabled = false;
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = hqImage;

    const logo = new Image();

    logo.src = LogoSrc;

    img.onload = function () {
      ctx.drawImage(img, 2000, 0, 1000, 1000); // Or at whatever offset you like

      setElementsLoaded((elementsLoaded) => elementsLoaded + 1);
    };
    logo.onload = function () {
      ctx.drawImage(logo, 1000, 378, 948, 187);
      setElementsLoaded((elementsLoaded) => elementsLoaded + 1);
    };
  };
  const drawOnMobileCanvas = () => {
    const canvasMobile = document.getElementById("myCanvasMobile");
    const ctxMobile = canvasMobile.getContext("2d");
    ctxMobile.fillStyle = bg_colors[background];

    ctxMobile.fillRect(0, 0, canvasMobile.width, canvasMobile.height);
    // ctxMobile.imageSmoothingEnabled = false;
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = hqImage;

    const logo = new Image();
    logo.src = LogoSrc;

    img.onload = function () {
      ctxMobile.drawImage(img, -96, 924, 1152, 1152); // Or at whatever offset you like
      setElementsLoaded((elementsLoaded) => elementsLoaded + 1);
    };
    logo.onload = function () {
      ctxMobile.drawImage(logo, 62, 750, 822, 162);
      setElementsLoaded((elementsLoaded) => elementsLoaded + 1);
    };
  };

  useEffect(() => {
    setElementsLoaded(0);
  }, [type]);

  function openImage(canvas) {
    canvas.toBlob((blob) => window.open(URL.createObjectURL(blob), "_blank"));
  }

  useEffect(() => {
    if (elementsLoaded === 2) {
      const canvasToOpen =
        type === 0
          ? document.getElementById("myCanvas")
          : document.getElementById("myCanvasMobile");
      openImage(canvasToOpen);
      setElementsLoaded(0);
    }
  }, [elementsLoaded]);

  const downloadHighQualityPfp = async () => {
    const link = document.createElement("a");
    link.href = hqImage;
    link.target = "_blank";
    // link.download = "PFP.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setElementsLoaded(0);
  };

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onDismiss();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onDismissModal = () => {
    onDismiss();
    setTimeout(() => {
      ref?.current?.scroll({
        top: 0,
      });
    }, 750);
  };

  return (
    <>
      <motion.div
        className={root}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className={modal}
          ref={ref}
          style={{ backgroundColor: bg_colors[background] }}
          initial={{ scale: 0.7 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.8 }}
          transition={{ duration: 0.4 }}
        >
          <div className={modal_header}>
            <motion.div className={title}>{content.title}</motion.div>

            <motion.span className={toggle} onClick={onDismissModal}>
              <span>Close &nbsp;</span>
              <span className={toggle}>X</span>
            </motion.span>
          </div>
          <motion.div className={modal_body}>
            <div className={styles.grid}>
              <div className={styles.image_container}>
                <img
                  src={hqImageModal}
                  alt="CowCow"
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                />
              </div>
              <div className={styles.data_container}>
                <div className={styles.general_data}>
                  <div className={styles.rank}>
                    <span>Rank {rarities[id]}</span>
                  </div>
                  <div className={styles.name}>{name}</div>
                </div>
                <div className={styles.traits}>
                  {traits.map((trait) => (
                    <div className={styles.trait}>
                      <h3>{trait.type}</h3>
                      <p>{trait.value}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.actions}>
                  <SelectHorizontal step={type} setStep={setType} />

                  {type === 0 && (
                    <button
                      disabled={!!elementsLoaded}
                      className={styles.download_button}
                      onClick={drawOnTwitterCanvas}
                    >
                      download
                    </button>
                  )}
                  {type === 1 && (
                    <button
                      disabled={!!elementsLoaded}
                      className={styles.download_button}
                      onClick={drawOnMobileCanvas}
                    >
                      download
                    </button>
                  )}
                  {type === 2 && (
                    <button
                      disabled={!!elementsLoaded}
                      className={styles.download_button}
                      onClick={downloadHighQualityPfp}
                    >
                      download
                    </button>
                  )}
                </div>
              </div>
            </div>
          </motion.div>

          <div className={styles.logos}>
            <a
              href="https://www.frameit.gg/marketplace/COW-cd463d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FrameIt} alt="FrameIt GG" />
            </a>
            <a
              href="https://xoxno.com/collection/COW-cd463d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={XOXNO} alt="XOXNO" />
            </a>
          </div>
        </motion.div>
      </motion.div>
      <CanvasTwitter />
      <CanvasMobile />
    </>
  );
};

export default ModalGallery;
