import React, { useEffect, useState } from "react";
import styles from "./GalleryPage.module.scss";
import Header from "../../Components/Header/Header";
import Loader from "../../Components/Loader/Loader";

import { ExtensionProvider } from "@elrondnetwork/erdjs-extension-provider";
import { useSearchParams } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";
import NFTCard from "../../Components/NFTCard/NFTCard";
import ModalGallery from "../../Components/ModalGallery/ModalGallery";
import { mapNftsToModalContentModel } from "../../utils/mappers";

import { AnimatePresence, motion } from "framer-motion";
import { PageTransitionAnimation } from "../../Animations";

const GalleryPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const [nftList, setNftList] = useState([]);

  const [modalContent, setModalContent] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  //pagination state
  const [page, setPage] = useState(0);
  const [finish, setFinish] = useState(false);

  //get elrond key from param
  const [searchParams] = useSearchParams();

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const provider = ExtensionProvider.getInstance();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    console.log("images loaded", imagesLoaded, totalImages);
    if (totalImages === imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        document.body.style.overflowY = "scroll";
      }, 1000);
    }
  }, [imagesLoaded]);
  const totalImages = nftList.length; // modify this if the number of images on page will change
  //set wallet connected maiar
  useEffect(() => {
    if (searchParams.get("address")) {
      sessionStorage.setItem(
        "elrondPublicAddress",
        searchParams.get("address")
      );
      setIsWalletConnected(true);
    }
  }, []);

  const getNftStatus = (address, page = 0) => {
    setLoaderVisible(true);
    fetch(
      `https://supply.mooveprotocol.ai/cowcow/${address}/?from=${
        page * 20
      }&size=${20 + page * 20}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setNftList((nftList) => [...nftList, ...json.data]);
        if (json.data.length < 19) {
          setFinish(true);
        }
        if (json.data.length === 0) {
          setTimeout(() => {
            setLoaderVisible(false);
            document.body.style.overflowY = "scroll";
          }, 1000);
        }
      });
  };

  const handleModalDismiss = () => {
    setIsModalOpen(false);
  };

  //set wallet connected defi
  const handleDefiLogin = async () => {
    await provider.init();
    if (!provider.isInitialized()) {
      window.open(
        "https://chrome.google.com/webstore/detail/multiversx-defi-wallet/dngmlblcodfobpdpecaadgfbcggfjfnm",
        "_ blank"
      );
    } else {
      const defiAddress = await provider.login();
      sessionStorage.setItem("elrondPublicAddress", defiAddress);
      getNftStatus(sessionStorage.getItem("elrondPublicAddress"));
      setIsWalletConnected(true);
    }
  };

  const handleLoadMore = () => {
    setPage((page) => page + 1);
  };

  const handleImageLoad = () => {
    setImagesLoaded((imagesLoaded) => imagesLoaded + 1);
  };

  useEffect(() => {
    if (sessionStorage.getItem("elrondPublicAddress")) {
      getNftStatus(sessionStorage.getItem("elrondPublicAddress"));
      setIsWalletConnected(true);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("elrondPublicAddress") && page !== 0) {
      getNftStatus(sessionStorage.getItem("elrondPublicAddress"), page);
    }
  }, [page]);

  const handleWalletDisconnect = () => {
    if (sessionStorage.getItem("elrondPublicAddress")) {
      sessionStorage.removeItem("elrondPublicAddress");
      setIsWalletConnected(false);
      setNftList([]);
      setImagesLoaded(0);
    }
  };

  return (
    <motion.div {...PageTransitionAnimation} className={styles.page}>
      {isloaderVisible && <Loader />}
      <Header />
      <BrowserView className={styles.main_content}>
        {!isWalletConnected ? (
          <div className={styles.container_buttons}>
            <h1 className={styles.heading_2}>
              Connect your wallet to continue
            </h1>
            <button onClick={handleDefiLogin}>MultiversX DeFi Wallet</button>

            <a
              href={`https://wallet.multiversx.com/hook/login?callbackUrl=${window.location.origin}/gallery`}
            >
              <span>XPortal Wallet</span>
            </a>
          </div>
        ) : (
          <>
            <h1 className={styles.heading}>Gallery</h1>
            <div className={styles.page_heading}>
              <div>
                {nftList.length ? (
                  <h2 className={styles.subheading}>
                    A list of all Cow Cow NFTs you own. Select the Cow you want
                    to proceed further.
                  </h2>
                ) : (
                  <h2 className={styles.subheading}>
                    No cows found in wallet.
                  </h2>
                )}
              </div>

              <button onClick={handleWalletDisconnect}>Disconnect</button>
            </div>
            <div className={styles.nft_grid}>
              {nftList &&
                nftList.map((nft, index) => (
                  <div
                    onClick={() => {
                      setModalContent(nft);
                      setIsModalOpen(true);
                    }}
                  >
                    <NFTCard
                      key={index}
                      image={nft.media[0].thumbnailUrl}
                      handleImageLoad={handleImageLoad}
                    />
                  </div>
                ))}
            </div>

            {!finish && nftList.length && (
              <div className={styles.load_more_container}>
                <button onClick={handleLoadMore}>Load More</button>
              </div>
            )}
          </>
        )}
      </BrowserView>
      <MobileView>
        <div className={styles.mobile_disclaimer}>
          <h1>You'll get the best experience on a laptop</h1>
          <p>
            For the moment, we don't support mobile devices. Please start the
            application on a device with a larger screen.
          </p>
        </div>
      </MobileView>
      <AnimatePresence>
        {isModalOpen && (
          <ModalGallery
            content={mapNftsToModalContentModel(modalContent)}
            onDismiss={handleModalDismiss}
          />
        )}
      </AnimatePresence>

      <footer className={styles.footer}>
        <span>&#169; 2023, Cow Cow</span>
        <span>Cow together, vibe together.</span>
      </footer>
    </motion.div>
  );
};

export default GalleryPage;
