import React, { useEffect } from "react";
import styles from "./NavMenu.module.scss";
import LogoSrc from "./assets/COWCOW_inverted.svg";
import { AnimatePresence, motion, useCycle } from "framer-motion";

import { Link } from "react-router-dom";
import { useState } from "react";
import useScrollBlock from "../../Hooks/useScrollBlock";

const NavMenu = ({ links, socials, inverted = false }) => {
  const [isMenuVisible, setIsMenuVisible] = useCycle(false, true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const toggleMenu = () => {
    setIsMenuVisible();
  };

  const parentVariants = {
    closed: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.3,
        staggerDirection: 1,
      },
    },
  };

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const toggleDropdown = () => {
    setIsDropdownVisible((isDropdownVisible) => !isDropdownVisible);
  };
  const handleBlur = () => {
    setTimeout(() => setIsDropdownVisible(false), 1000);
  };

  useEffect(() => {
    if (isMenuVisible) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => allowScroll();
  }, [isMenuVisible]);

  const renderMenu = (links) => {
    return links.map((link) =>
      link.submenu ? (
        <div className={styles.nav_item_with_submenu}>
          <motion.li
            key={link.route}
            className={styles.nav_item}
            variants={itemVariants}
          >
            <div onClick={toggleDropdown}>
              {link.title}
              {!inverted ? (
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 2.25L7 6.75L2 2.25"
                    stroke="#FEEFC4"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 2.25L7 6.75L2 2.25"
                    stroke="#c75227"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                </svg>
              )}
            </div>
          </motion.li>
          {isDropdownVisible && (
            <div className={styles.submenu} onBlur={handleBlur}>
              {link.submenu.map((submenuItem) => (
                <motion.li
                  key={submenuItem.route}
                  className={styles.nav_item}
                  variants={itemVariants}
                  onBlur={handleBlur}
                >
                  {submenuItem.route.includes("https") ? (
                    <a
                      href={submenuItem.route}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {submenuItem.title}
                    </a>
                  ) : (
                    <Link to={submenuItem.route}>{submenuItem.title}</Link>
                  )}
                </motion.li>
              ))}
            </div>
          )}
        </div>
      ) : (
        <motion.li
          key={link.route}
          className={styles.nav_item}
          variants={itemVariants}
        >
          {link.route.includes("https") ? (
            <a href={link.route} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          ) : (
            <Link to={link.route}>{link.title}</Link>
          )}
        </motion.li>
      )
    );
  };

  const renderMenuMobile = (links) => {
    return links.map((link) =>
      link.submenu ? (
        link.submenu.map((submenuItem) => (
          <motion.li
            key={submenuItem.route}
            className={styles.nav_item}
            variants={itemVariants}
            onBlur={handleBlur}
          >
            {submenuItem.route.includes("https") ? (
              <a
                href={submenuItem.route}
                target="_blank"
                rel="noopener noreferrer"
              >
                {submenuItem.title}
              </a>
            ) : (
              <Link to={submenuItem.route}>{submenuItem.title}</Link>
            )}
          </motion.li>
        ))
      ) : (
        <motion.li
          key={link.route}
          className={styles.nav_item}
          variants={itemVariants}
        >
          {link.route.includes("https") ? (
            <a href={link.route} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          ) : (
            <Link to={link.route}>{link.title}</Link>
          )}
        </motion.li>
      )
    );
  };

  const renderSocials = (socials) => {
    return socials.map((social) => {
      return (
        <li key={social.route} className={styles.nav_item}>
          <a href={social.route}>
            <img src={social.icon} alt={social.route} />
          </a>
        </li>
      );
    });
  };

  const renderSocialsMobile = (socials) => {
    return socials.map((social) => {
      return (
        <li key={social.route} className={styles.nav_item}>
          <a href={social.route}>
            <img src={social.iconMobile} alt={social.route} />
          </a>
        </li>
      );
    });
  };

  return (
    <nav className={!inverted ? styles.menu : styles.menu_inverted}>
      <div className={styles.nav_container_desktop}>
        <AnimatePresence>
          <ul>
            {renderMenu(links)}
            {!inverted ? renderSocials(socials) : renderSocialsMobile(socials)}
          </ul>
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {isMenuVisible && (
          <motion.div
            className={styles.nav_container_mobile}
            initial={{ opacity: 1, scale: 1.5, x: "150%" }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            exit={{ opacity: 1, scale: 1.5, x: "150%" }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <div className={styles.nav_header}>
              <img src={LogoSrc} alt={"cowcow"} className={styles.logo} />

              <span className={styles.toggle} onClick={toggleMenu}>
                X
              </span>
            </div>
            <motion.ul
              initial="closed"
              animate="open"
              exit="closed"
              variants={parentVariants}
            >
              {renderMenuMobile(links)}
            </motion.ul>
            <div className={styles.nav_footer}>
              <ul className={styles.socials}>{renderSocialsMobile(socials)}</ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <span className={styles.toggle} onClick={toggleMenu}>
        {!inverted ? (
          <svg
            width="26"
            height="20"
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 4)"
              stroke="#C75227"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 12)"
              stroke="#C75227"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 20)"
              stroke="#C75227"
              stroke-width="4"
              stroke-linecap="round"
            />
          </svg>
        ) : (
          <svg
            width="26"
            height="20"
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 4)"
              stroke="#FEEFC4"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 12)"
              stroke="#FEEFC4"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              x1="2"
              y1="-2"
              x2="24"
              y2="-2"
              transform="matrix(-1 0 0 1 26 20)"
              stroke="#FEEFC4"
              stroke-width="4"
              stroke-linecap="round"
            />
          </svg>
        )}
      </span>
    </nav>
  );
};

export default NavMenu;
