import React from "react";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import LogoSrc from "./assets/logo_new.svg";
import InvertedLogoSrc from "./assets/COWCOW_inverted.svg";

import NavMenu from "../NavMenu/NavMenu";
import links from "../../consts/links";
import socials from "../../consts/socials";

const Header = ({ inverted = false }) => {
  const { header, logo } = styles;
  return (
    <header className={header}>
      <div>
        <Link to={"/"}>
          {!inverted ? (
            <img src={LogoSrc} alt={"cowcow"} className={logo} />
          ) : (
            <img src={InvertedLogoSrc} alt="CowCow" className={logo} />
          )}
        </Link>
      </div>
      <NavMenu links={links} socials={socials} inverted={inverted} />
    </header>
  );
};
export default Header;
