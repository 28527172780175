import React, { useEffect, useState } from "react";
import styles from "./UrbanPlanPage.module.scss";
import UrbanPlan from "./assets/urban-plan-map.webp";
import UrbanPlanMobile from "./assets/urban-plan-portrait.webp";
import ModalUrbanPlan from "../../Components/ModalUrbanPlan/ModalUrbanPlan";
import {
  atelier_obj,
  central_station_obj,
  factory_obj,
  mall_obj,
  social_hub_obj,
  town_square_obj,
  uni_obj,
} from "./content.js";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import { motion } from "framer-motion";
import { FadeOutAnimation, PageTransitionAnimation } from "../../Animations";
import Loader from "../../Components/Loader/Loader";
import useScrollBlock from "../../Hooks/useScrollBlock";

const UrbanPlanPage = () => {
  const {
    plan_container,
    map_label,
    atelier,
    mall,
    uni,
    town,
    factory,
    social_hub,
    central_station,
    page_heading,
    desktop_img,
    mobile_img,
  } = styles;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isloaderVisible, setIsLoaderVisible] = useState(true);

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    document
      .querySelector("html")
      ?.setAttribute("style", "background: var(--brown);");
    document.body.style = "background: var(--brown)";

    return () => {
      document
        .querySelector("html")
        ?.setAttribute("style", "background: var(--beige);");
      document.body.style = "";
    };
  }, []);

  const [modalContent, setModalContent] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalDismiss = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    blockScroll();
    if (imageLoaded) {
      setTimeout(() => {
        setIsLoaderVisible(false);
        allowScroll();
      }, 1000);
    }
  }, [imageLoaded]);

  return (
    <motion.div {...PageTransitionAnimation}>
      {isloaderVisible && <Loader />}
      <Header inverted />
      <div className={page_heading}>
        <h1>Urban Plan</h1>
        <h2>Mooving together to The City</h2>
      </div>
      <div className={plan_container}>
        <motion.img
          className={desktop_img}
          src={UrbanPlan}
          alt="The urban plan"
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          transition={{ duration: 0.35 }}
          onLoad={() => setImageLoaded(true)}
        />
        <motion.img
          className={mobile_img}
          src={UrbanPlanMobile}
          alt="The urban plan"
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          transition={{ duration: 0.35 }}
          onLoad={() => setImageLoaded(true)}
        />
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={atelier}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(atelier_obj);
            setIsModalOpen(true);
          }}
        >
          Atelier
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={mall}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(mall_obj);
            setIsModalOpen(true);
          }}
        >
          The Mall
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={uni}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(uni_obj);
            setIsModalOpen(true);
          }}
        >
          University
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={town}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(town_square_obj);
            setIsModalOpen(true);
          }}
        >
          Town Square
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={factory}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(factory_obj);
            setIsModalOpen(true);
          }}
        >
          Factory
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={social_hub}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(social_hub_obj);
            setIsModalOpen(true);
          }}
        >
          Social Hub
        </motion.div>
        <motion.div
          animate={isModalOpen ? "inactive" : "active"}
          variants={FadeOutAnimation}
          id={central_station}
          className={map_label}
          transition={{ duration: 0.2 }}
          onClick={() => {
            setModalContent(central_station_obj);
            setIsModalOpen(true);
          }}
        >
          Central <br /> Station
        </motion.div>

        <motion.div
          animate={isModalOpen ? "active" : "inactive"}
          variants={FadeOutAnimation}
          transition={{ delay: 0.2 }}
        >
          <ModalUrbanPlan
            content={modalContent}
            onDismiss={handleModalDismiss}
          />
        </motion.div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default UrbanPlanPage;
