import React, { useEffect, useState } from "react";
import styles from "./MissionPage.module.scss";
import Page from "../../HOC/Page/Page";
import MissionSrc from "./assets/manifesto.webp";
import Loader from "../../Components/Loader/Loader";
import useScrollBlock from "../../Hooks/useScrollBlock";

const MissionPage = () => {
  const {
    container,
    heading,
    subheading,
    mission_text,
    mission_img,
    grid,
    grid_element,
    grid_element__heading,
    grid_element__description,
    grid_top,
    section,
  } = styles;

  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const [blockScroll, allowScroll] = useScrollBlock();
  const totalImages = 1; // modify this if the number of images on page will change
  useEffect(() => {
    blockScroll();
    if (totalImages === imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        allowScroll();
      }, 1000);
    }
  }, [imagesLoaded]);

  return (
    <Page>
      {isloaderVisible && <Loader />}

      <main className={container}>
        <section>
          <h1 className={heading}>Manifesto</h1>
          <h2 className={subheading}>The power of community</h2>
          <article className={`${grid_top}`}>
            <section className={mission_text}>
              <p>
                We believe in the power of community. <br /> The importance of
                connection. <br /> The potential of collective wisdom. <br />
                The effect of pushing our limits.
              </p>
              <p>
                And we are determined to break the norm and create a unique
                brand.
              </p>
              <p>
                To gather around a community of friends. <br /> To empower them.
                <br /> To give them wings. <br /> And then, to reach the
                incredible together.
              </p>
              <p>
                We design, create and deliver a premium brand that will put the
                MultiversX blockchain on the world map of NFTs. We will bring
                awareness and visibility to this space and we will create
                unprecedented opportunities for our members.
              </p>
              <p>
                We will spread our values and through this, we will achieve a
                culture of innovation within our community.
              </p>
              <p>
                So come along and join the herd, let's moove forward together.
              </p>
              <p>
                Juntos. Ensemble. Zusammen. Insieme. Juntos. Samen. Вместе.
                一起. 一緒に. 함께. معا. Μαζί. יחד. সাথে. Bersama. Razem.
                Birlikte. Tillsammans. Sammen. Sammen. Yhdessä. Společně.
                Együtt. Spolu. Zajedno. Zajedno. Заедно. Împreună. Kartu. Kopā.
                Koos. Saman. Le Chéile. Gyda'n Gilydd. Còmhla. Cómhla. ہم ساتھ.
                সাথে. Bersama. ร่วมกัน. Cùng Nhau. ជាមួយគេ. Bersama-Sama.
                Magkasama. Magkasama. Saam. Pamoja. Rẹ́. Ọ̀rọ̀. Ngaphakathi.
                Ngexesha. Ke Ke Ke.
              </p>
            </section>
            <section className={mission_img}>
              <img
                src={MissionSrc}
                alt={"CowCow-Mission"}
                onLoad={() =>
                  setImagesLoaded((imagesLoaded) => imagesLoaded + 1)
                }
              />
            </section>
          </article>
        </section>
        <section className={section}>
          <h1 className={heading}>Values</h1>
          <h2 className={subheading}>What's in for you?</h2>
          <article className={grid}>
            <div className={grid_element}>
              <h3 className={grid_element__heading}>Cowmoonity first</h3>
              <p className={grid_element__description}>
                Cow together. Community is the fuel to any project and that's
                why Cow Cow is all about community. The Cowmoonity is and will
                always be the #1 value for Cow Cow. Cow together, vibe together!
              </p>
            </div>
            <div className={grid_element}>
              <h3 className={grid_element__heading}>Good vibes only</h3>
              <p className={grid_element__description}>
                Cow Cow is the place where you take your daily dose of good
                vibes. We will always maintain our “good vibes only” attitude
                around The City and our main purpose is to spread it all around
                web3.
              </p>
            </div>
            <div className={grid_element}>
              <h3 className={grid_element__heading}>Paint the story</h3>
              <p className={grid_element__description}>
                Cow Cow journey has just began and will be a very entertaining
                one. New chapters will be added periodically, and community
                members will have the chance to paint the path and be part of
                the story.
              </p>
            </div>
            <div className={grid_element}>
              <h3 className={grid_element__heading}>Cownt on us</h3>
              <p className={grid_element__description}>
                We are building more than just a brand, we are creating a way of
                life. Good things take time, especially when we are speaking
                about new fields. One thing is sure: you can COWnt on us. Can we
                do the same?
              </p>
            </div>
          </article>
        </section>
      </main>
    </Page>
  );
};
export default MissionPage;
