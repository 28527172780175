import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./Footer.module.scss";

const Footer = () => {
  const location = useLocation();
  const { footer, egld } = styles;
  const currentRoute = location.pathname;

  if (currentRoute === "/thecity") {
    return (
      <footer className={footer}>
        <iframe
          src="https://egld.community/api/products/13c69f10-e331-4554-a92c-0da3c1c2e297/upvotes/embed?theme=tertiary&size=sm"
          width="136"
          height="40"
          className={egld}
        ></iframe>
        <span>&#169; 2024, Two3 Labs LTD. All Rights Reserved.</span>
        {/*<span>Cow together, vibe together.</span>*/}
      </footer>
    );
  } else {
    return (
      <footer className={footer}>
        <span>&#169; 2024, Two3 Labs LTD. All Rights Reserved.</span>
        {/*<span>Cow together, vibe together.</span>*/}
      </footer>
    );
  }
};
export default Footer;
