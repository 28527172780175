export const mapNftsToModalContentModel = (nft) => {
  return {
    image: nft.media[0].thumbnailUrl,
    id: nft.name.split("#").at(-1),
    hqImage: `https://coffee-dear-mongoose-513.mypinata.cloud/ipfs/QmP8XL56WtNnRvWUXHh1W8MLAjekMyY5JtMw5FC72Lf3bK/${nft.name
      .split("#")
      .at(-1)}.png`,
    hqImageModal: `https://coffee-dear-mongoose-513.mypinata.cloud/ipfs/QmP8XL56WtNnRvWUXHh1W8MLAjekMyY5JtMw5FC72Lf3bK/${nft.name
      .split("#")
      .at(-1)}.png`,
    name: nft.name,
    background: Object.values(
      nft.metadata.attributes.find(
        (attribute) => attribute.trait_type === "Background"
      )
    )[1],
    traits: nft.metadata.attributes.map((attribute) => ({
      type: attribute.trait_type,
      value: attribute.value,
    })),
  };
};

export const defaultModalContentModel = {
  image: "",
  id: "",
  hqImage: "",
  hqImageModal: "",
  name: "",
  background: "cream",
  traits: [],
};
