import React from "react";
import "./index.css";
import { useLocation, useRoutes } from "react-router-dom";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import HomePage from "./Pages/HomePage/HomePage";
import PlaceholderPage from "./Pages/PlaceholderPage/PlaceholderPage";
import { AnimatePresence } from "framer-motion";
import AboutPage from "./Pages/AboutPage/AboutPage";
import TeamPage from "./Pages/TeamPage/TeamPage";
import MissionPage from "./Pages/MissionPage/MissionPage";
import NonSensePage from "./Pages/NonSensePage/NonSensePage";
import UrbanPlanPage from "./Pages/UrbanPlanPage/UrbanPlanPage";
import AnticipationPage from "./Pages/AnticipationPage/AnticipationPage";
import GalleryPage from "./Pages/GalleryPage/GalleryPage";
import HerdsPage from "./Pages/HerdsPage/HerdsPage";
function App() {
  const element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/placeholder",
      element: <PlaceholderPage />,
    },
    {
      path: "/thecity",
      element: <AboutPage />,
    },
    {
      path: "/team",
      element: <TeamPage />,
    },
    {
      path: "/manifesto",
      element: <MissionPage />,
    },
    {
      path: "/thebrand",
      element: <NonSensePage />,
    },
    {
      path: "/urban-plan",
      element: <UrbanPlanPage />,
    },
    {
      path: "/gallery",
      element: <GalleryPage />,
    },
    //Temporarly Disabled
    // {
    //   path: "/herds",
    //   element: <HerdsPage />,
    // },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  const location = useLocation();
  return (
    <AnimatePresence>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}

export default App;
