const tba_23 =
  "One TWO THREE four. Two and Three. Are you wondering what this is about? You will find out more soon. ";
const tba_origins =
  "Cow Cow stories will take you back in time to see the entire history of the cows. Back to origins. Cowing soon.";
const tba_bell = "You know how it goes! Don't you?";
const tba_key =
  "“Sometimes you have to put a wrench in the gears to get people to listen.”  More will be here soon.";
const tba_cone = "Building takes time. On the way.";
const tba_gallery = "Prepare your cameras. Art is life and life is art.";

export { tba_23, tba_bell, tba_cone, tba_gallery, tba_key, tba_origins };
