import React, { useRef } from "react";

const CanvasTwitter = (props) => {
  const canvasRef = useRef(null);
  return (
    <canvas
      ref={canvasRef}
      {...props}
      id="myCanvas"
      width="3000"
      height="1000"
    />
  );
};

export default CanvasTwitter;
