import React, { useEffect, useState } from "react";
import styles from "./AboutPage.module.scss";
import BG_Remake from "./assets/BG_Remake_new.webp";
import BG_Remake_Mobile from "./assets/thecity.webp";
import Story from "./assets/story.png";
import About from "./assets/about.png";
import Herdship from "./assets/herdship.png";
import Illustration from "./assets/illustrationB/desktop/new.webp";
import Page from "../../HOC/Page/Page";

import {
  BounceUpWhileInView,
  DiagonalLeftWhileInView,
  DiagonalRightWhileInView,
  FadeInWhileInView,
  PageTransitionAnimation,
} from "../../Animations";

import { motion } from "framer-motion";
import Loader from "../../Components/Loader/Loader";
import useScrollBlock from "../../Hooks/useScrollBlock";

const AboutPage = () => {
  const {
    page,
    header,
    grid,
    row,
    row_text,
    heading,
    subheading,
    details,
    flex,
    center_text,
    container,
    bordered,
    bottomArticle,
    topArticle,
    bottom_image,
    bottom_image_container,
  } = styles;

  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const [blockScroll, allowScroll] = useScrollBlock();
  const totalImages = 4; // modify this if the number of images on page will change
  useEffect(() => {
    blockScroll();
    if (totalImages <= imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        allowScroll();
      }, 1000);
    }
  }, [imagesLoaded]);

  return (
    <Page>
      {isloaderVisible && <Loader />}

      <header className={header}>
        <picture>
          <source srcSet={BG_Remake_Mobile} media="(max-width: 768px)" />
          <img
            src={BG_Remake}
            alt={"CowCow-Town"}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
        </picture>
      </header>
      <article className={`${flex} ${container} ${topArticle}`}>
        <motion.h1 className={heading} {...BounceUpWhileInView}>
          Building <br /> the city
        </motion.h1>
        <motion.h3 className={subheading} {...BounceUpWhileInView}>
          Let's moove together
        </motion.h3>
        <motion.p
          className={`${details} ${center_text}`}
          {...BounceUpWhileInView}
        >
          Cow Cow is not just an NFT project, it is a moovement. &nbsp;
          <br />
          For all the ones looking for a home, we are building you a city.
          &nbsp;
          <br />
          Are you ready to move into The City?
        </motion.p>
      </article>
      <article className={`${grid} ${container}`}>
        {/*STORY*/}
        <div className={row}>
          <section className={row_text}>
            <div className={flex}>
              <motion.h2
                className={`${heading} ${bordered}`}
                {...BounceUpWhileInView}
              >
                Story
              </motion.h2>
            </div>
            <motion.p className={details} {...DiagonalLeftWhileInView}>
              As the world around them changed, so too did the cows' curiosity
              grow. They made the bold decision to leave their home in the
              countryside and embark on a journey to The City. Cow Cow stories
              will take you back in time to see the entire history of the cows,
              but it will also tell you about present and future adventures.
            </motion.p>
          </section>
          <motion.img
            src={Story}
            alt={"Story"}
            {...DiagonalRightWhileInView}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
        </div>

        {/*ABOUT*/}
        <div className={row}>
          <motion.img
            src={About}
            alt={"About"}
            {...DiagonalLeftWhileInView}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
          <section className={row_text}>
            <div className={flex}>
              <motion.h2
                className={`${heading} ${bordered}`}
                {...BounceUpWhileInView}
              >
                About
              </motion.h2>
            </div>
            <motion.p className={details} {...DiagonalRightWhileInView}>
              Cow Cow is more than just a logo or a tagline. It's a living,
              breathing entity that connects with people on an emotional level.
              A lifestyle brand that connects visual storytelling, experiences,
              and in-depth lore to empower the community. The 10.000 NFT holders
              are mooving together on MultiversX and will become The City
              residents.
            </motion.p>
          </section>
        </div>

        {/*HERDSHIP*/}
        <div className={row}>
          <section className={row_text}>
            <div className={flex}>
              <motion.h2
                className={`${heading} ${bordered}`}
                {...BounceUpWhileInView}
              >
                Herdship
              </motion.h2>
            </div>
            <motion.p className={details} {...DiagonalLeftWhileInView}>
              Cow Cow is breaking the chain's barriers, bringing together people
              with a shared belief in the community. We will design, build and
              deliver a premium brand, creating unprecedented opportunities for
              our members both in web3 and IRL. Built together with the
              community, for the community.
            </motion.p>
          </section>
          <motion.img
            src={Herdship}
            alt={"Herdship"}
            {...DiagonalRightWhileInView}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
        </div>
      </article>
      <article className={`${flex} ${container} ${bottomArticle}`}>
        <motion.h1 className={heading} {...BounceUpWhileInView}>
          Join The Crew
        </motion.h1>
        <motion.h3 className={subheading} {...BounceUpWhileInView}>
          ChapterX: There's a long line...
        </motion.h3>
        <motion.p
          className={`${details} ${center_text}`}
          {...BounceUpWhileInView}
        >
          And none of them are looking like giving up. What are they waiting
          for? What's there, so important to have all the attention of the Cows?
          <br />
          <br />
          Once we become the residents of The City, expect multiple adventures
          to happen. ChapterX of Cow Cow coming soon.
        </motion.p>
        <motion.div className={bottom_image_container} {...BounceUpWhileInView}>
          <picture>
            <img
              src={Illustration}
              alt={"The line is getting bussier"}
              className={bottom_image}
            />
          </picture>
        </motion.div>
      </article>
    </Page>
  );
};
export default AboutPage;
