import React, { useEffect, useState } from "react";
import styles from "./TeamPage.module.scss";
import { crew_list } from "./crew_list";
import CrewCard from "../../Components/CrewCard/CrewCard";
import Page from "../../HOC/Page/Page";
import Loader from "../../Components/Loader/Loader";
import useScrollBlock from "../../Hooks/useScrollBlock";
const TeamPage = () => {
  const {
    container,
    team_avatar,
    grid,
    heading,
    banner_desktop,
    banner_mobile,
  } = styles;

  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isloaderVisible, setLoaderVisible] = useState(true);
  const [blockScroll, allowScroll] = useScrollBlock();
  const totalImages = 2; // modify this if the number of images on page will change
  useEffect(() => {
    blockScroll();
    if (totalImages === imagesLoaded) {
      setTimeout(() => {
        setLoaderVisible(false);
        allowScroll();
      }, 1000);
    }
  }, [imagesLoaded]);

  return (
    <Page>
      {isloaderVisible && <Loader />}
      <main>
        <section className={team_avatar}>
          <img
            src={process.env.PUBLIC_URL + "assets/Team/header_desktop.png"}
            alt={"CowCow-Team"}
            className={banner_desktop}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
          <img
            src={process.env.PUBLIC_URL + "assets/Team/header_mobile.png"}
            alt={"CowCow-Team"}
            className={banner_mobile}
            onLoad={() => setImagesLoaded((imagesLoaded) => imagesLoaded + 1)}
          />
        </section>
        <section className={container}>
          <h1 className={heading}>Meet our team</h1>
        </section>
        <section className={`${container} ${grid}`}>
          {crew_list.map((member) => {
            return (
              <CrewCard
                avatar={member.avatar}
                name={member.name}
                role={member.role}
                description={member.description}
                twitter={member.twitter}
              />
            );
          })}
        </section>
      </main>
    </Page>
  );
};
export default TeamPage;
